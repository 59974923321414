import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Web3 from "web3";

import Header from "./components/Header";
import Main from "./pages/Main";
import Membership from "./pages/Membership";
import styles from "./assets/css/App.module.css";
import { useDispatch } from "react-redux";
import { setNFTList } from "./modules/cube";

//  MainNet
// const CHAIN_NETWORK = "8898";
// const MEMBERSHIP_CUBE_ADDRESS = "0xf2189eBf7e00CdBde1A600A72F45fD0fa961e404";

//  TestNet

// const CHAIN_NETWORK = "88998";
// const MEMBERSHIP_CUBE_ADDRESS = "0xDAA79ebda77F2a9D66Df260469f92BBc4d62706a";
// const MEMBERSHIP_CUBE_ADDRESS_2 = "0x32eDCB150D6FD73797B1fa2e5114D08b3BB4a65C";
// const MEMBERSHIP_CUBE_ADDRESS_3 = "0x1a16f19a90a5906ba12DB8f8ad3f19ea5B423b0b";
function App() {
  const dispatch = useDispatch();

  const [CHAIN_NETWORK, setCHAIN_NETWORK] = useState("");
  const [MEMBERSHIP_CUBE_ADDRESS, setMEMBERSHIP_CUBE_ADDRESS] = useState("");
  const [MEMBERSHIP_CUBE_ADDRESS_3rd, setMEMBERSHIP_CUBE_ADDRESS_3rd] =
    useState("");

  const MEMBERSHIP_CUBE_ADDRESS_2 = "";

  const [account, setAccount] = useState("");
  const [nowUrl, setNowURL] = useState("");
  const [chainId, setChainId] = useState("");
  const [web3Provider, setWeb3Provider] = useState();
  const [windowEther, setWindowEther] = useState();
  const [active, setActive] = useState(false);

  const checkInject = async () => {
    let inject = window.localStorage.getItem("MemberShip_Cube");
    inject = JSON.parse(inject);
    try {
      if (inject.inject && inject.chainId == CHAIN_NETWORK) {
        onClickConnectWallet();
      }
    } catch (error) {
      // window.location.replace("/")
    }
  };

  const onClickConnectWallet = async () => {
    if (window.ethereum != undefined) {
      let web3MetaMask;
      let accounts;
      let chainNum;
      let windowEhters;

      if (window.ethereum.overrideIsMetaMask) {
        for (const [key, value] of window.ethereum.providerMap.entries()) {
          if (key === "MetaMask") {
            value.enable();
            web3MetaMask = new Web3(value);
            windowEhters = value;
            accounts = await value.send("eth_requestAccounts");
            accounts = accounts.result[0];
            chainNum = await web3MetaMask.eth.getChainId();
          }
        }
      } else if (
        window.ethereum.overrideIsMetaMask == undefined &&
        window.ethereum.isMetaMask
      ) {
        window.ethereum.enable();
        web3MetaMask = new Web3(window.ethereum);
        windowEhters = window.ethereum;
        accounts = await window.ethereum.send("eth_requestAccounts");
        accounts = accounts.result[0];
        chainNum = await web3MetaMask.eth.getChainId();
      } else if (window.ethereum.isMetaMask == false) {
        window.open(`https://metamask.app.link/dapp/member.mmtchain.io/`);
      }
      if (String(chainNum) == CHAIN_NETWORK) {
        setWindowEther(windowEhters);
        setWeb3Provider(web3MetaMask);
        setAccount(accounts);
        setChainId(chainNum);
        setActive(true);

        let setData = {
          inject: true,
          account: accounts,
          chainId: chainNum,
        };

        window.localStorage.setItem("MemberShip_Cube", JSON.stringify(setData));
      } else if (String(chainNum) != CHAIN_NETWORK) {
        onChangeNetWork(windowEhters);
      }
    } else {
      window.open(`https://metamask.app.link/dapp/member.mmtchain.io/`);
    }
  };

  const onChangeNetWork = async (windowEthers) => {
    let netWorkVersion;
    let _rpcUrl;
    let blockExplorerURL;
    let chainName;
    if (CHAIN_NETWORK == 88998) {
      // testnet
      netWorkVersion = "0x15ba6";
      _rpcUrl = "https://data-seed-premmt-1.mmtscan.io/";
      blockExplorerURL = "https://testnet.mmtscan.io";
      chainName = "Mammoth TestNet";
    } else if (CHAIN_NETWORK == 8898) {
      //  mainnet
      netWorkVersion = "0x22c2";
      _rpcUrl = "https://dataseed.mmtscan.io/";
      blockExplorerURL = "https://mmtscan.io";
      chainName = "Mammoth Pro";
    }

    try {
      await windowEthers.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: netWorkVersion,
          },
        ],
      });
    } catch (error) {
      // if (error.code === 4902) {
      if (error) {
        try {
          await windowEthers.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: netWorkVersion,
                chainName: chainName,
                rpcUrls: [_rpcUrl],
                nativeCurrency: {
                  name: "Mammoth",
                  symbol: "MMT",
                  decimals: 18,
                },
                blockExplorerUrls: [blockExplorerURL],
              },
            ],
          });
        } catch (addError) {}
      }
    }
  };

  const onClickDisConnect = async () => {
    window.localStorage.removeItem("MemberShip_Cube");
    // let data = {
    //   first:"0",
    //   third:"0",
    // }
    dispatch(setNFTList([]));
    setAccount("");
    setChainId(0);
    setActive(false);
    setWindowEther();
    setWeb3Provider();
  };

  // if (window.ethereum != undefined) {
  //   window.ethereum.on("accountsChanged", async function (accountsed) {
  //     onClickDisConnect();
  //   });

  //   window.ethereum.on("chainChanged", async function (chainId) {
  //     onClickDisConnect();
  //   });
  // }
  if (windowEther != undefined) {
    windowEther.on("accountsChanged", async function (accounts) {
      if (account != accounts[0]) {
        onClickDisConnect();
      }
    });

    windowEther.on("chainChanged", async function (chainId) {
      onClickDisConnect();
    });
  }

  useEffect(async () => {
    // const web3 = new Web3(window.web3.currentProvider);
    if (CHAIN_NETWORK != "") {
      checkInject();
    }
  }, [CHAIN_NETWORK]);

  // useEffect(() => {
  //   setNowURL(window.location.href.split('/')[3]);
  // }, [nowUrl]);

  const envChainId = process.env.REACT_APP_NETWORK_CHAINID;

  useEffect(() => {
    if (envChainId == "8898") {
      setCHAIN_NETWORK("8898");
      setMEMBERSHIP_CUBE_ADDRESS("0xf2189eBf7e00CdBde1A600A72F45fD0fa961e404");
      setMEMBERSHIP_CUBE_ADDRESS_3rd(
        "0x6B1b9537493Dbb9c504fEC5A9BB84E9741F805a3"
      );
    } else if (envChainId == "88998") {
      setCHAIN_NETWORK("88998");
      // setMEMBERSHIP_CUBE_ADDRESS("0xDAA79ebda77F2a9D66Df260469f92BBc4d62706a");
      // setMEMBERSHIP_CUBE_ADDRESS("0x8656f52e5465C4E26512A68B53E77569C83FdD5F");
      setMEMBERSHIP_CUBE_ADDRESS("0x68518A9406Be38B9e82D609C5539336fAC9470Ad");
      setMEMBERSHIP_CUBE_ADDRESS_3rd(
        "0xBcC8E054aF49cF133922aCba31D9499088154b96"
      );
    }
  }, []);

  return (
    <BrowserRouter>
      <div className={`${nowUrl == "/" ? styles.wrapBg : styles.wrapBgBlack}`}>
        {nowUrl == "/" ? (
          <img
            className={styles.wrapImg}
            src={require("./assets/images/img_main_bg@2x.png")}
            alt={"main"}
          />
        ) : (
          ""
        )}
        <Header
          onClickConnectWallet={onClickConnectWallet}
          onClickDisConnect={onClickDisConnect}
          account={account}
          chainId={chainId}
          active={active}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Main
                setNowUrl={setNowURL}
                web3Provider={web3Provider}
                account={account}
                chainId={chainId}
                active={active}
                MEMBERSHIP_CUBE_ADDRESS={MEMBERSHIP_CUBE_ADDRESS}
                MEMBERSHIP_CUBE_ADDRESS_2={MEMBERSHIP_CUBE_ADDRESS_2}
                MEMBERSHIP_CUBE_ADDRESS_3={MEMBERSHIP_CUBE_ADDRESS_3rd}
              />
            }
          />
          {/* <Route
            path="/"
            element={
              <Membership
                setNowUrl={setNowURL}
                web3Provider={web3Provider}
                account={account}
                chainId={chainId}
                active={active}
                MEMBERSHIP_CUBE_ADDRESS={MEMBERSHIP_CUBE_ADDRESS}
                MEMBERSHIP_CUBE_ADDRESS_2={MEMBERSHIP_CUBE_ADDRESS_2}
                MEMBERSHIP_CUBE_ADDRESS_3={MEMBERSHIP_CUBE_ADDRESS_3rd}
              />
            }
          /> */}
          <Route
            path="/membership"
            element={
              <Membership
                setNowUrl={setNowURL}
                web3Provider={web3Provider}
                account={account}
                chainId={chainId}
                active={active}
                MEMBERSHIP_CUBE_ADDRESS={MEMBERSHIP_CUBE_ADDRESS}
                MEMBERSHIP_CUBE_ADDRESS_2={MEMBERSHIP_CUBE_ADDRESS_2}
                MEMBERSHIP_CUBE_ADDRESS_3={MEMBERSHIP_CUBE_ADDRESS_3rd}
              />
            }
          />
        </Routes>

        <div className={styles.copyRight}>
          ©2022 Mammoth. All rights reserved.
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

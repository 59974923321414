import { createAction, handleAction, handleActions } from 'redux-actions';
import { put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import produce from 'immer';
import axios from 'axios';

const initialState = {
    nftList: [],
    nftDetail: {
        ch: "",
        index: "",
        totalCoin: 0,
        lockCoin: 0,
        unlockCoin: 0,
        staking: 0,
        dropCoin: 0,
        rangeBgWidth: "",
        rangeArrowLeft: ""
    },
    status :"",
};

const SETNFTLIST = "cube/SETNFTLIST";
export const setNFTList = createAction(SETNFTLIST, (input) => input);

// ======================================================
const GETNFTLIST = "cube/GETNFTLIST";
export const getNFTList = createAction(GETNFTLIST, (input) => input);


export function* waitGetNFTList() {
    yield takeEvery(GETNFTLIST, dbGetNFTDetailList)
}

function* dbGetNFTDetailList(body) {
    let initData;
    let nftLists = body.payload;
    let url = "http://localhost:3000"
    initData = yield call([axios, "post"], "/membership/getMyNFTList", {
        nftLists
    });

    if (initData.status == 200) {
        yield put(setNFTList(initData.data.result))
    }

}

// MMT 전송
// ======================================================

const CLICKWITHDRAW = "cube/CLICKWITHDRAW";
export const clickWithdraw = createAction(CLICKWITHDRAW, (input) => input);

export function* waitClickWithdraw() {
    yield takeEvery(CLICKWITHDRAW, dbClickWithdraw)
}

function* dbClickWithdraw(body) {
    let initDataStaking;
    let initDataUnlock;

    let { account, no, stakingCoin, unlockCoin,contractAddress } = body.payload;


    
    if(unlockCoin != "0"){
        initDataUnlock = yield call([axios, "post"],"/membership/transferUnlockCoin",{
            account,
            no,
            unlockCoin,
            contractAddress
        })
    }

    if(initDataUnlock.data.status == 200 ){
        yield put(setStatus("success"))
    }else{
        yield put(setStatus("failed"))
    }
}
// ======================================================

const CLICKWITHDRAWREWARD = "cube/CLICKWITHDRAWREWARD";
export const clickWithdrawReward = createAction(CLICKWITHDRAWREWARD, (input) => input);

export function* waitClickWithdrawReward() {
    yield takeEvery(CLICKWITHDRAWREWARD, dbClickWithdrawReward)
}

function* dbClickWithdrawReward(body) {
    let initDataStaking;
    let initDataUnlock;
    let { account, no, stakingCoin, unlockCoin, contractAddress } = body.payload;
    
    if(stakingCoin != "0"){
        initDataStaking = yield call([axios, "post"],"/membership/transferStakingCoin",{
            account,
            no,
            stakingCoin,
            contractAddress
        })
    }

    if(initDataStaking.data.status ==200){
        yield put(setStatus("success"))
    }else{
        yield put(setStatus("failed"))
    }
}

// GMMT 전송
// ======================================================

const CLICKWITHDRAWGMMT = "cube/CLICKWITHDRAWGMMT";
export const clickWithdrawGMMT = createAction(CLICKWITHDRAWGMMT, (input) => input);

export function* waitClickWithdrawGMMT() {
    yield takeEvery(CLICKWITHDRAWGMMT, dbClickWithdrawGMMT)
}

function* dbClickWithdrawGMMT(body) {
    let initDataStaking;
    let initDataUnlock;
    let { account, no, stakingCoin, unlockCoin,contractAddress } = body.payload;


    
    if(unlockCoin != "0"){
        initDataUnlock = yield call([axios, "post"],"/membership/transferUnlockCoinGMMT",{
            account,
            no,
            unlockCoin,
            contractAddress
        })
    }

    if(initDataUnlock.data.status == 200 ){
        yield put(setStatus("success"))
    }else{
        yield put(setStatus("failed"))
    }
}

// ======================================================
const SETSTATUS = "cube/SETSTATUS";
export const setStatus = createAction(SETSTATUS, (input) => input);

const cube = handleActions({
    [SETNFTLIST]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.nftList = input;
        }),
    [SETSTATUS]: (state, { payload: input }) =>
        produce(state, (draft) => {
            draft.status = input;
        }),
}, initialState);

export default cube
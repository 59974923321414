import { createAction, handleAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { put, takeEvery } from 'redux-saga/effects';

const initialState = {
    balanceOf:{
        first: 0,
        third: 0,
    },
};

const SETBALANCE = "user/SETBALANCE";
export const setBalanceNFT = createAction(SETBALANCE, (user) => user);

const DBSETBALANCE = "user/DBSETBALANCE";
export const dbSetBalance = createAction(DBSETBALANCE, (input)=>input);

export function* waitDbSetBalance () {
    yield takeEvery(DBSETBALANCE, dbSetNFTBalance)
}

function* dbSetNFTBalance(body){
    let initData
    let first = body.payload.first;
    let third = body.payload.third;
    let nftList = body.payload.nftList;
    
    let data = {
        first : first,
        third : third
    }
    
    yield put(setBalanceNFT(data))
}


const user = handleActions(
    {
        [SETBALANCE] : (state, {payload: input}) =>
        produce(state, (draft)=>{
                draft.balanceOf.first = input.first;
                draft.balanceOf.third = input.third;
            })

    }, initialState
);

export default user;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../assets/css/Header.module.css'

const Header = ({ onClickConnectWallet, onClickDisConnect, account, chinId, active }) => {
  const [reAccount, setReAccount] = useState('');
  const [addressShow, setAddressShow] = useState(false);
  const [copyStatus, setCopyStatus] = useState(false);

  const accountReNumber = () => {
    const startStr = account.substring(0, 3);
    const endStr = account.substring(account.length, account.length - 3);

    setReAccount(startStr + '...' + endStr);
  }

  const addressToggle = () => {
    setAddressShow(!addressShow)
  };

  const onClickCopyWalletAddress = async (address) => {
    if (navigator.clipboard && navigator.permissions) {
      await navigator.clipboard.writeText(address);

    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea');
      ele.value = address;
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
    }
  }


  useEffect(() => {
    if (account != "") {
      accountReNumber()
    }
  }, [account]);

  useEffect(()=>{
    if(copyStatus){
      setTimeout(()=>{
        setCopyStatus(false)
      },[800])
    }
  },[copyStatus])



  return (
    <div>
      <div className={styles.header}>
        <div className={styles.headerInner}>
          <Link to={"/"}>
            <img className={styles.logo} src={require("../assets/images/Mammoth_logo_horizontal_white@2x.png")} alt={"logo"} />
          </Link>
          {active && account != "" ?
            <button className={styles.connectBtn} onClick={addressToggle}>{reAccount}</button>
            :
            <button className={styles.connectBtn} onClick={onClickConnectWallet}>Connect Wallet</button>
          }
          {
            addressShow &&
            <div className={styles.walletInfoComponent}>
              <div className={styles.walletInfoDim} onClick={addressToggle} ></div>
              <div className={styles.walletInfo}>
                <div className={styles.walletInfoContainer} >
                  <div className={styles.addressTitle} >My address</div>
                  <div className={styles.address} 
                    style={{cursor:"pointer"}}
                  onClick={() => {
                    onClickCopyWalletAddress(account);
                    setCopyStatus(true);
                  }}>{account}</div>
                  <div className={`${styles.copied} ${copyStatus?styles.nowCopied:""}`}>copied</div>
                  <Link to={"/"}>
                    <button className={styles.disConnectBtn} onClick={() => {
                      onClickDisConnect();
                      addressToggle();
                    }}>Disconnect</button>
                  </Link>
                </div>
              </div>
            </div>
          }


        </div>
      </div>
    </div>
  )
}

export default Header
// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_wrapBg__19ycF {\n    width: 100%;\n    min-height: 100vh;\n    position: relative;\n}\n\n.App_wrapBgBlack__2rZeq {\n    width: 100%;\n    min-height: 100vh;\n    position: relative;\n    background: #000;\n}\n\n.App_wrapBg__19ycF img.App_wrapImg__22GC7 {\n    position: absolute;\n    z-index: -1;\n    top: 0;\n    left: 49.99%;\n    transform: translate(-50%, 0);\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.App_copyRight__Mzj6D {\n    position: absolute;\n    left: 50%;\n    bottom: 5rem;\n    transform: translate(-50%, 0);\n    color: #fff;\n    font-weight: 700;\n    font-size: 3rem;\n    white-space: nowrap;\n}", "",{"version":3,"sources":["webpack://src/assets/css/App.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,MAAM;IACN,YAAY;IACZ,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,6BAA6B;IAC7B,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".wrapBg {\n    width: 100%;\n    min-height: 100vh;\n    position: relative;\n}\n\n.wrapBgBlack {\n    width: 100%;\n    min-height: 100vh;\n    position: relative;\n    background: #000;\n}\n\n.wrapBg img.wrapImg {\n    position: absolute;\n    z-index: -1;\n    top: 0;\n    left: 49.99%;\n    transform: translate(-50%, 0);\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.copyRight {\n    position: absolute;\n    left: 50%;\n    bottom: 5rem;\n    transform: translate(-50%, 0);\n    color: #fff;\n    font-weight: 700;\n    font-size: 3rem;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapBg": "App_wrapBg__19ycF",
	"wrapBgBlack": "App_wrapBgBlack__2rZeq",
	"wrapImg": "App_wrapImg__22GC7",
	"copyRight": "App_copyRight__Mzj6D"
};
export default ___CSS_LOADER_EXPORT___;

// <<<<<<< HEAD
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Web3 from 'web3';
import MemberShip_Cube_JSON from "../abi/Membership_Cube.json";
import { dbSetBalance, setBalanceNFT } from "../modules/user";
import styles from '../assets/css/Main.module.css';
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import resolve from 'resolve';



const Main = ({ setNowUrl, web3Provider, account, chainId, active, MEMBERSHIP_CUBE_ADDRESS, history, MEMBERSHIP_CUBE_ADDRESS_2, MEMBERSHIP_CUBE_ADDRESS_3 }) => {
  let navigate = useNavigate();

  // finn - start
  const first = useSelector(state => state.user.balanceOf.first);
  const second = useSelector(state => state.user.balanceOf.second);
  const third = useSelector(state => state.user.balanceOf.third);

  const [accessMessageState, setAccessMessageState] = useState(false);

  const dispatch = useDispatch();


  // 메인넷 오류 남 확인 ==> 오래걸려서 나는 오류....
  const checkMyMemberShip = async (e) => {
    // try {
      if (active && account != "" && web3Provider != undefined && MEMBERSHIP_CUBE_ADDRESS != "" && MEMBERSHIP_CUBE_ADDRESS_3 != "") {
        // const web3 = new Web3(window.web3.currentProvider);
        // const web3 = new Web3(window.ethereum);
        const web3 = web3Provider;



        // 1 => MEMBERSHIP_CUBE_ADDRESS
        const contract_1st = new web3.eth.Contract(MemberShip_Cube_JSON, MEMBERSHIP_CUBE_ADDRESS);

        const balance_1st = await contract_1st.methods.balanceOf(account).call();
        
        // // 2 => MEMBERSHIP_CUBE_ADDRESS_2
        // const contract_2st = new web3.eth.Contract(MemberShip_Cube_JSON, MEMBERSHIP_CUBE_ADDRESS_2);
        // const balance_2st = await contract_2st.methods.balanceOf(account).call();
        
        // // 3 => MEMBERSHIP_CUBE_ADDRESS_3
        const contract_3st = new web3.eth.Contract(MemberShip_Cube_JSON, MEMBERSHIP_CUBE_ADDRESS_3);
        const balance_3st = await contract_3st.methods.balanceOf(account).call();

        let promise = [];
        let result = [];

        // for (let i = 0; i < balance_1st; i++) {
        //   promise.push(new Promise(async (resolve, reject) => {
        //     let index = await contract_1st.methods.tokenOfOwnerByIndex(account, i).call();
        //     resolve(index);
        //   }))
        // }

        // for (let i = 0; i < balance_3st; i++) {
        //   promise.push(new Promise(async (resolve, reject) => {
        //     let index = await contract_3st.methods.tokenOfOwnerByIndex(account, i).call();
        //     resolve(index);
        //   }))
        // }

        // Promise.all(promise).then(value => {
        //   let data = {
        //     first: balance_1st,
        //     nftList :value
        //     // second: balance_2st,
        //     // third: balance_3st
        //   }


        //   if (data.first == "0") {
        //     setAccessMessageState(true)
        //   } else {
        //     setAccessMessageState(false)
        //   }
        //   // dispatch(setBalanceNFT(data,value));
        //   dispatch(dbSetBalance(data))
        // });

        let data = {
          first: balance_1st,
          third: balance_3st,
          nftList: result
          // second: balance_2st,
        }
        dispatch(dbSetBalance(data))
        if(balance_1st != "0" || balance_3st != "0"){
          setAccessMessageState(false)
        }else{
          setAccessMessageState(true)
        }


        // if (balance_1st == "0" && balance_2st == "0" && balance_3st == "0") {
      } else if (account == "" && web3Provider == undefined) {
        let data = {
          first: "0",
          third: "0",
          nftList: []
          // second: "0",
          // third: "0"
        }
        // dispatch(setBalanceNFT(data))
        dispatch(dbSetBalance(data))
        setAccessMessageState(false)
      }
    // } catch (error) {
      // console.log("error", error)
    // }
  }

  useEffect(() => {
    checkMyMemberShip();
  }, [account, active, chainId, web3Provider, MEMBERSHIP_CUBE_ADDRESS, MEMBERSHIP_CUBE_ADDRESS_3]);

  const { pathname } = useLocation();

  useEffect(() => {
    setNowUrl(pathname);
  }, [pathname]);


  useEffect(()=>{

  },[]);


  return (
    <div className={styles.position}>
      <div className={styles.small}>About</div>
      <div className={styles.bold}>your NFT</div>
      <Link to={first != "0" || third !="0" ? "/membership" : ""} >
        <button className={styles.memberShipBtn} >View My Membership</button>
      </Link>
      {accessMessageState == true ?
        <div className={styles.warning}>
          <span className={styles.WNbold}>'View My Membership'</span> <span className={styles.whitefail}> page is only accessible</span> <br />
          <span className={styles.whitefail}> to membership cube owners.</span>
        </div>
        : ""}
    </div>
  )
}

export default React.memo(Main)
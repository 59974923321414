import { combineReducers } from "redux";
import { all } from "@redux-saga/core/effects";
import { persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import user, { waitDbSetBalance } from "./user"
import cube, { waitClickWithdraw, waitClickWithdrawGMMT, waitClickWithdrawReward, waitGetNFTList } from "./cube"


const persistConfig = {
    key: "MemberShip_Cube",
    storage,
    whitelist: [
        "user",
    ],
}


const rootreducer = combineReducers({
    user,
    cube
});


export function* rootsaga() {
    yield all([
        waitDbSetBalance(),
        waitGetNFTList(),
        waitClickWithdraw(),
        waitClickWithdrawReward(),
        waitClickWithdrawGMMT(),
    ]);
}

const persistedReducer = persistReducer(persistConfig, rootreducer);

export default persistedReducer;
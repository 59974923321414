import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import styles from "../assets/css/Membership.module.css";
import MemberShip_Cube_JSON from "../abi/Membership_Cube.json";

// import { setBalance } from "../modules/user";

import Slider from "react-slick";
import "../assets/css/slick.css";
import "../assets/css/slick-theme.css";
import { setBalanceNFT } from "../modules/user";

import { useLocation, useNavigate } from "react-router-dom";
import { BN } from "bn.js";
import {
  clickWithdraw,
  clickWithdrawReward,
  getNFTList,
  setStatus,
  clickWithdrawGMMT,
} from "../modules/cube";

const Membership = ({
  setNowUrl,
  web3Provider,
  account,
  chainId,
  active,
  MEMBERSHIP_CUBE_ADDRESS,
  MEMBERSHIP_CUBE_ADDRESS_2,
  MEMBERSHIP_CUBE_ADDRESS_3,
}) => {
  const navigate = useNavigate();

  // const [myNFTList, setMyNFTList] = useState("");
  const [myNFTList, setMyNFTList] = useState([]);
  const [dropCoin_1, setDropCoin_1] = useState(16);
  const [dropCoin_2, setDropCoin_2] = useState(19);
  const [dropCoin_3, setDropCoin_3] = useState(21);
  const [percent, setPercent] = useState(0);

  const [modalStatus, setModalStatus] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalItem, setModalItem] = useState();

  const [unlockInputAmount, setUnlockInputAmount] = useState("");
  const [stakingInputAmount, setStakingInputAmount] = useState("");

  const [withdrawAmount, setWithdrawAmount] = useState("0");

  const [buttonStatus, setButtonStatus] = useState(false);

  const [unlockInputStatus, setUnlockInputStatus] = useState(true);
  const [stakingInputStatus, setStakingInputStatus] = useState(true);

  const [withdrawType, setWithdrawType] = useState("");

  const [approveStatus, setApproveStatus] = useState(false);

  const [mobile, setMobile] = useState("L");
  const [windowWidth, setWindowWidth] = useState("");

  const [walletAddress, setWalletAddress] = useState("");
  const [inputAddressStatus, setInputAddressStatus] = useState(true);
  const [transferItem, setTransferItem] = useState();

  const [transferStatus, setTransferStatus] = useState(false);

  const [balance, setBalance] = useState("");

  const first = useSelector((state) => state.user.balanceOf.first);
  const second = useSelector((state) => state.user.balanceOf.second);
  const third = useSelector((state) => state.user.balanceOf.third);

  const nftList = useSelector((state) => state.cube.nftList);
  const status = useSelector((state) => state.cube.status);

  // finn - start

  let contractAddressList = [
    MEMBERSHIP_CUBE_ADDRESS,
    // MEMBERSHIP_CUBE_ADDRESS_2,
    // MEMBERSHIP_CUBE_ADDRESS_3,
  ];

  const dispatch = useDispatch();

  let web3 = web3Provider;

  useEffect(() => {
    if (web3Provider != undefined) {
      web3 = web3Provider;
    }
  }, [web3Provider]);

  const checkMyMemberShip = async (e) => {
    let getData;
    if (
      web3Provider != undefined &&
      account != "" &&
      active != false &&
      chainId != ""
    ) {
      await getNFT(MEMBERSHIP_CUBE_ADDRESS);
    }
    // setMyNFTList(getData);
  };

  const getNFT = async (contractAddress) => {
    let result = [];
    let contract = new web3.eth.Contract(MemberShip_Cube_JSON, contractAddress);
    let balance = await contract.methods.balanceOf(account).call();
    let contract_3 = new web3.eth.Contract(
      MemberShip_Cube_JSON,
      MEMBERSHIP_CUBE_ADDRESS_3
    );
    let balance_3 = await contract_3.methods.balanceOf(account).call();

    let userNFTList = [];
    let promise = [];

    if (balance != "0" || balance_3 != "0") {
      if (balance != "0") {
        for (let x = 0; x < balance; x++) {
          promise.push(
            new Promise(async (resolve, reject) => {
              let index = await contract.methods
                .tokenOfOwnerByIndex(account, x)
                .call();
              resolve(index);
            })
          );
        }
      }
      if (balance_3 != "0") {
        for (let x = 0; x < balance_3; x++) {
          promise.push(
            new Promise(async (resolve, reject) => {
              let index = await contract_3.methods
                .tokenOfOwnerByIndex(account, x)
                .call();
              resolve(index);
            })
          );
        }
      }

      Promise.all(promise).then((values) => {
        result = values;
        dispatch(getNFTList(result));
      });
    } else if (balance == "0" || balance_3 == "0") {
      navigate("/");
    }

    return result;
  };

  // 1651363200
  // 1675227600

  function toLocaleStringOption(amount) {
    const parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const withDrawStatus = (index) => {
    let startDt =
      Number(index) >= 2500
        ? new Date("2022-10-01")
        : Number(index) >= 1000
        ? new Date("2022-06-21")
        : (Number(index) >= 194 && Number(index) <= 199) || Number(index) == 999
        ? new Date("2022-06-21")
        : new Date("2022-05-01");
    // let laterMonth = new Date(startDt.setMonth(startDt.getMonth() + 0));
    let laterMonth = new Date(startDt.setMonth(startDt.getMonth() + 6));
    let laterTimeStamp = laterMonth.getTime() / 1000;

    let condition = Math.floor(new Date().getTime() / 1000);
    if (laterTimeStamp <= condition) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (
      web3Provider != undefined &&
      account != "" &&
      active != false &&
      chainId != ""
    ) {
      checkMyMemberShip();
    }
  }, [
    account,
    active,
    chainId,
    web3Provider,
    dropCoin_1,
    dropCoin_2,
    dropCoin_3,
    status,
  ]);

  const [rangeBgWidth, setRangeBgWidth] = useState();
  const [ragneArrowLeft, setRangeArrowLeft] = useState();

  const [rangeBgWidth_2, setRangeBgWidth_2] = useState();
  const [ragneArrowLeft_2, setRangeArrowLeft_2] = useState();

  const [rangeBgWidth_3, setRangeBgWidth_3] = useState();
  const [ragneArrowLeft_3, setRangeArrowLeft_3] = useState();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    setRangeBgWidth({ width: "0px" });
    setRangeArrowLeft({ left: "-2px" });
  }, []);

  useEffect(() => {
    const nowDt = new Date();

    const startDt = new Date("2022-05-01");
    const startDt_2 = new Date("2022-06-21");
    const startDt_3 = new Date("2022-10-01");

    const diff = nowDt - startDt;
    const diff_2 = nowDt - startDt_2;
    const diff_3 = nowDt - startDt_3;

    const diffDay = Math.floor(diff / (1000 * 60 * 60 * 24));
    const diffDay_2 = Math.floor(diff_2 / (1000 * 60 * 60 * 24));
    const diffDay_3 = Math.floor(diff_3 / (1000 * 60 * 60 * 24));

    setDropCoin_1(diffDay * dropCoin_1);
    setDropCoin_2(diffDay_2 * dropCoin_2);
    setDropCoin_3(diffDay_3 * dropCoin_3);
  }, []);

  useEffect(() => {
    if (first == "0" && second == "0" && third == "0") {
      navigate("/");
      // window.location.replace("/")
    }
  }, [myNFTList]);

  const { pathname } = useLocation();

  useEffect(() => {
    setNowUrl(pathname);
  }, [pathname]);

  useEffect(() => {
    let inject = window.localStorage.getItem("MemberShip_Cube");
    if (inject == null) {
      navigate("/");
      // window.location.replace("/")
    }
  }, [account]);

  const onClickMYCOIN = (bool) => {
    setStakingInputAmount("");
    setUnlockInputAmount("");
    setStakingInputStatus(true);
    setUnlockInputStatus(true);
    setModalStatus(bool);
  };

  const onChangeInputValue = (type, amount) => {
    let pattern_1 = /^[0-9]*[.]?[0-9]*$/;
    let pattern = /^\d{0,10}$/;
    let condition_1 = checkInputAmountDecimal(amount);
    if (pattern.test(amount) && condition_1) {
      if (type === "staking") {
        setStakingInputAmount(amount);
        if (
          subAmount(modalItem.total_reward, modalItem.withdrawed_reward) <
          Number(amount)
        ) {
          setStakingInputStatus(false);
        } else {
          setStakingInputStatus(true);
        }
      } else if (type === "unlock") {
        setUnlockInputAmount(amount);
        if (
          subAmount(modalItem.unlocked_mmt, modalItem.withdrawed_mmt) <
          Number(amount)
        ) {
          setUnlockInputStatus(false);
        } else {
          setUnlockInputStatus(true);
        }
      } else if (type === "unlockGMMT") {
        setUnlockInputAmount(amount);
        if (
          subAmount(modalItem.unlocked_gmmt, modalItem.withdrawed_gmmt) <
          Number(amount)
        ) {
          setUnlockInputStatus(false);
        } else {
          setUnlockInputStatus(true);
        }
      }
    } else if (amount == "") {
      if (type === "staking") {
        setStakingInputAmount(amount);
        setStakingInputStatus(true);
      } else if (type === "unlock") {
        setUnlockInputAmount(amount);
        setUnlockInputStatus(true);
      } else if (type === "unlockGMMT") {
        setUnlockInputAmount(amount);
        setUnlockInputStatus(true);
      }
    }
  };

  const onClickApprove = async () => {
    let condition_1 = withdrawAmount != "0";
    let condition_2 = Number(modalItem.unlockCoin) >= Number(unlockInputAmount);
    let condition_3 = Number(modalItem.staking) >= Number(stakingInputAmount);

    if (condition_1 && condition_2 && condition_3) {
      setModalType("comfirm");
    }
  };

  const checkInputAmountDecimal = (amount) => {
    try {
      if (amount != "" && amount != undefined) {
        let index = amount.indexOf(".");
        if (index != -1) {
          let decimalLength = amount.substr(index + 1, amount.length);
          if (decimalLength.length <= 18) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeWithDrawAmount = (unlockInputAmount, stakingInputAmount) => {
    let amountA = unlockInputAmount == "" ? "0" : unlockInputAmount;
    let amountB = stakingInputAmount == "" ? "0" : stakingInputAmount;

    if (web3 != undefined) {
      let toWeiUnlockAmount = web3.utils.toWei(amountA);
      let toWeiStakingInputAmount = web3.utils.toWei(amountB);

      let sum = new BN(`${toWeiUnlockAmount}`)
        .add(new BN(`${toWeiStakingInputAmount}`))
        .toString();

      sum = web3.utils.fromWei(sum);

      setWithdrawAmount(sum);
    }
  };

  const subAmount = (a, b) => {
    // console.log(a,b)
    let result = Number(a) >= Number(b) ? new BN(`${a}`).sub(new BN(`${b}`)).toString() : new BN(`${b}`).sub(new BN(`${a}`)).toString();
    // let result = new BN(`${a}`).sub(new BN(`${b}`)).toString();
    return result;
  };

  const subAmountView = (a,b) => {
    if(b > a){
      return 0;
    }else{
      return  new BN(`${a}`).sub(new BN(`${b}`)).toString();
    }
  }

  const myCoin = (unlock, totalReward, withdraw, withdrawReward) => {
    let addValue = new BN(`${unlock}`).add(new BN(`${totalReward}`)).toString();
    let subValue = new BN(`${withdraw}`)
      .add(new BN(`${withdrawReward}`))
      .toString();

    let result = new BN(`${addValue}`).sub(new BN(`${subValue}`)).toString();
    return result;
  };

  const rangeBgWidthValue = (item) => {
    let value = 0;
    value = ((item.unlocked_gmmt + item.unlocked_mmt) / item.total_value) * 100;

    return Math.floor(value);
  };

  const percentValue = (item) => {
    let value = 0;
    value = ((item.unlocked_gmmt + item.unlocked_mmt) / item.total_value) * 100;

    return Math.floor(value);
  };

  const rangeArrowLeftValue = (item) => {
    // let percent = (item.unlocked_value / item.total_value) * 100;
    let percent = 5;
    let value = mobile == "L" ? -11 : mobile == "M" ? -7 : -4;
    if (percent == 0) {
      return value;
    } else {
      if (mobile) {
      } else {
      }
      return value;
    }
  };

  const onClickConfirm = async () => {
    let contractAddress =
      modalItem.no >= 0 && modalItem.no <= 2499
        ? MEMBERSHIP_CUBE_ADDRESS
        : MEMBERSHIP_CUBE_ADDRESS_3;
    let sendData = {
      no: modalItem.no,
      account: account,
      unlockCoin: unlockInputAmount == "" ? "0" : `${unlockInputAmount}`,
      stakingCoin: stakingInputAmount == "" ? "0" : `${stakingInputAmount}`,
      contractAddress: contractAddress,
    };

    if (withdrawType == "withdraw") {
      dispatch(clickWithdraw(sendData));
    } else if (withdrawType == "staking") {
      dispatch(clickWithdrawReward(sendData));
    }
  };

  const onClickConfirmGMMT = async () => {
    let contractAddress =
      modalItem.no >= 0 && modalItem.no <= 2499
        ? MEMBERSHIP_CUBE_ADDRESS
        : MEMBERSHIP_CUBE_ADDRESS_3;
    let sendData = {
      no: modalItem.no,
      account: account,
      unlockCoin: unlockInputAmount == "" ? "0" : `${unlockInputAmount}`,
      stakingCoin: stakingInputAmount == "" ? "0" : `${stakingInputAmount}`,
      contractAddress: contractAddress,
    };

    if (withdrawType == "withdrawGMMT") {
      dispatch(clickWithdrawGMMT(sendData));
    }
  };

  const checkApproveStatus = () => {
    if (withdrawType == "withdraw") {
      if (
        Number(unlockInputAmount) != 0 &&
        unlockInputAmount != "" &&
        Number(unlockInputAmount) <=
          Number(subAmount(modalItem.unlocked_mmt, modalItem.withdrawed_mmt))
      ) {
        setApproveStatus(true);
      } else {
        setApproveStatus(false);
      }
    } else if (withdrawType == "staking") {
      if (
        Number(stakingInputAmount) != 0 &&
        stakingInputAmount != "" &&
        Number(stakingInputAmount) <=
          Number(subAmount(modalItem.total_reward, modalItem.withdrawed_reward))
      ) {
        setApproveStatus(true);
      } else {
        setApproveStatus(false);
      }
    } else if (withdrawType == "withdrawGMMT") {
      if (
        Number(unlockInputAmount) != 0 &&
        unlockInputAmount != "" &&
        Number(unlockInputAmount) <=
          Number(subAmount(modalItem.unlocked_gmmt, modalItem.withdrawed_gmmt))
      ) {
        setApproveStatus(true);
      } else {
        setApproveStatus(false);
      }
    }
  };

  const onClickTransferModal = (item) => {
    setTransferItem(item);
    setWalletAddress("");
    setModalType("transfer");
    setModalStatus(true);
    setInputAddressStatus(true);
  };

  const onChangeWalletAddress = (address) => {
    setWalletAddress(address);
  };

  const checkWalletAddress = () => {
    if (walletAddress == "") {
      setInputAddressStatus(true);
    } else if (walletAddress.substring(0, 2) != "0x") {
      setInputAddressStatus(false);
    } else if (walletAddress.substring(0, 2) == "0x") {
      if (walletAddress.length != 42) {
        setInputAddressStatus(false);
      } else if (walletAddress.length == 42) {
        setInputAddressStatus(true);
      }
    }
  };

  const onClickTransferConfirm = () => {
    setModalType("transferConfirm");
  };

  const onClickTransferNFT = async (item) => {
    let contractAddress =
      item.no >= 0 && item.no <= 2499
        ? MEMBERSHIP_CUBE_ADDRESS
        : MEMBERSHIP_CUBE_ADDRESS_3;
    let contract = new web3.eth.Contract(MemberShip_Cube_JSON, contractAddress);
    setTransferStatus(true);

    try {
      let result = await contract.methods
        .safeTransferFrom(account, walletAddress, String(item.no))
        .send({
          from: account,
        });
      if (result.status) {
        dispatch(setStatus("success"));
        setTransferStatus(false);
      } else {
        setTransferStatus(false);
        dispatch(setStatus("failed"));
      }
    } catch (error) {
      console.log(error);
      if (error.code == 4001) {
        setTransferStatus(false);
      }
    }
  };

  const checkBalance = async () => {
    if (account != "") {
      let balance = await web3.eth.getBalance(account);
      balance = web3.utils.fromWei(balance);
      setBalance(balance);
    }
  };

  useEffect(() => {
    checkBalance();
  }, [account]);

  useEffect(() => {
    changeWithDrawAmount(unlockInputAmount, stakingInputAmount);
  }, [unlockInputAmount, stakingInputAmount]);

  useEffect(() => {
    if (status == "success") {
      if (transferItem == undefined) {
        setModalType("complete");
      } else {
        setModalType("transferComplete");
      }
    } else if (status == "failed") {
      setModalType("failed");
    }
  }, [status, transferItem]);

  useEffect(() => {
    checkApproveStatus();
  }, [withdrawType, unlockInputAmount, stakingInputAmount, modalItem]);

  const checkdetectMoileDevice = (agent, width) => {
    const mobileRegex = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    // setMobileCheck(mobileRegex.some(mobile => agent.match(mobile)));
    let type = width > 720 ? "L" : width > 480 ? "M" : "S";
    setMobile(type);
  };

  useEffect(async () => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
        checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
      });
    };
  }, []);

  useEffect(() => {
    checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
  }, []);

  useEffect(() => {
    checkWalletAddress();
  }, [walletAddress]);

  useEffect(() => {
    setModalStatus(true);
    setModalType("notice");
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.mainTitle}>MY NFT MEMBERSHIP CUBE</div>
      {/* <div className={styles.nftItem}>*/}
      {/*    <img src={require("../assets/images/img_membership_1.png")} alt={"nft_item"} />*/}
      {/*    <img className={styles.bg} src={require("../assets/images/img_membership_bg_01.png")} alt={"nft_bg"} />*/}
      {/*</div> */}
      <div className={styles.slider}>
        {nftList == "" ? (
          ""
        ) : (
          <Slider {...settings}>
            {nftList.map((item, index) => (
              <div key={index}>
                <div>
                  <div className={styles.nftItem}>
                    {Number(item.no) >= 2500 ? (
                      <div>
                        <img
                          className={styles.nftCube}
                          src={require("../assets/images/img_membership_3.png")}
                          alt={"nft_item"}
                        />
                        <img
                          className={styles.bg}
                          src={require("../assets/images/img_membership_bg_03.png")}
                          alt={"nft_bg"}
                        />
                      </div>
                    ) : Number(item.no) >= 1000 ? (
                      <div>
                        <img
                          className={styles.nftCube}
                          src={require("../assets/images/img_membership_2.png")}
                          alt={"nft_item"}
                        />
                        <img
                          className={styles.bg}
                          src={require("../assets/images/img_membership_bg_02.png")}
                          alt={"nft_bg"}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className={styles.nftCube}
                          src={require("../assets/images/img_membership_1.png")}
                          alt={"nft_item"}
                        />
                        <img
                          className={styles.bg}
                          src={require("../assets/images/img_membership_bg_01.png")}
                          alt={"nft_bg"}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.nftNumber}>
                    <div className={styles.text}>NFT 고유번호</div>
                    <div className={styles.number}>
                      {toLocaleStringOption(`${item.no}`)}
                    </div>
                  </div>
                </div>

                <div className={styles.dropDate}>
                  <div className={styles.title}>드롭분배기간</div>
                  {/*<div className={styles.tgeUnlock}>TGE <br /> UNLOCK </div>*/}
                  <div className={styles.totalCoin}>
                    Total Coin : {toLocaleStringOption(`${item.total_value}`)}{" "}
                    MMT
                  </div>
                  <div>
                    <div className={styles.graphNumber}>
                      <div>0</div>
                      <div>10</div>
                      <div>20</div>
                      <div>30</div>
                      <div>40</div>
                      <div>50</div>
                      <div>60</div>
                      <div>70</div>
                      <div>80</div>
                      <div>90</div>
                      <div>100</div>
                    </div>
                    <div className={styles.graphLine}>
                      <div>
                        <span></span>
                      </div>
                      <div className={styles.small}>
                        <span></span>
                      </div>
                      <div>
                        <span></span>
                      </div>
                      <div className={styles.small}>
                        <span></span>
                      </div>
                      <div>
                        <span></span>
                      </div>
                      <div className={styles.small}>
                        <span></span>
                      </div>
                      <div>
                        <span></span>
                      </div>
                      <div className={styles.small}>
                        <span></span>
                      </div>
                      <div>
                        <span></span>
                      </div>
                      <div className={styles.small}>
                        <span></span>
                      </div>
                      <div>
                        <span></span>
                      </div>
                      <div className={styles.small}>
                        <span></span>
                      </div>
                      <div>
                        <span></span>
                      </div>
                      <div className={styles.small}>
                        <span></span>
                      </div>
                      <div>
                        <span></span>
                      </div>
                      <div className={styles.small}>
                        <span></span>
                      </div>
                      <div>
                        <span></span>
                      </div>
                      <div className={styles.small}>
                        <span></span>
                      </div>
                      <div>
                        <span></span>
                      </div>
                      <div className={styles.small}>
                        <span></span>
                      </div>
                      <div>
                        <span></span>
                      </div>
                    </div>
                    <div className={styles.rangeBox}>
                      <div className={styles.rangeDiv}>
                        <div
                          className={styles.rangeBg}
                          style={{ width: `${rangeBgWidthValue(item)}%` }}
                        >
                          <div className={styles.rangeArrow}>
                            {/* <img src={require("../assets/images/ico_arrow@2x.png")} style={{float:`${Number(rangeBgWidthValue(item))>90?"right":Number(rangeBgWidthValue(item))==50?"":"left"}`}} alt={"arrow"} /> */}
                            <span>UNLOCK({percentValue(item)}%)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* MMT */}
                    <div className={styles.coinStatus}>
                      <div className={styles.lockCoin}>
                        <div>TOTAL</div>
                        <div>{toLocaleStringOption(`${item.total_value}`)}</div>
                        <div>MMT</div>
                      </div>
                      <div
                        className={`${styles.staking} ${
                          withDrawStatus(item.no) ? styles.withDraw : ""
                        }`}
                        // onClick={() => {
                        //   if (withDrawStatus(item.no) == true) {
                        //     onClickMYCOIN(true);
                        //     setWithdrawType("withdraw");
                        //     setModalItem(item);
                        //     setModalType("withdraw");
                        //   }
                        //   // setModalType("comfirm");
                        //   // setModalType("complete");
                        // }}
                      >
                        <div>LOCK</div>
                        {/* <div>0</div> */}
                        <div>
                          {toLocaleStringOption(
                            // subAmount(
                              `${item.locked_mmt}`,
                              // `${item.unlocked_mmt}`
                            // )
                          )}
                        </div>
                        <div>MMT</div>
                      </div>
                      {/* ${styles.withDraw} */}
                      <div
                        className={`${styles.myCoin} ${
                          withDrawStatus(item.no) ? styles.withDraw : ""
                        }`}
                        onClick={() => {
                          if (withDrawStatus(item.no) == true) {
                            onClickMYCOIN(true);
                            setWithdrawType("withdraw");
                            setModalItem(item);
                            setModalType("withdraw");
                          }
                          // setModalType("comfirm");
                          // setModalType("complete");
                        }}
                      >
                        <div>
                          {/* {withDrawStatus(item.no) ? "WITHDRAW" : "MY COIN"} */}
                          UNLOCK
                        </div>
                        <div>
                          {toLocaleStringOption(
                            subAmount(
                              // `${item.total_reward}`,
                              // `${item.withdrawed_reward}`
                              `${item.unlocked_mmt}`,
                              `${item.withdrawed_mmt}`
                            )
                          )}
                        </div>
                        <div>MMT</div>
                      </div>
                    </div>
                    {/* GMMT */}
                    <div
                      className={`${styles.coinStatus} ${styles.gmmtStatus}`}
                    >
                      <div
                        className={`${styles.myCoin} ${
                          withDrawStatus(item.no) ? styles.reward : ""
                        }`}
                        onClick={() => {
                          if (withDrawStatus(item.no) == true) {
                            onClickMYCOIN(true);
                            setWithdrawType("staking");
                            setModalItem(item);
                            setModalType("withdraw");
                          }
                        }}
                      >
                        <div>REWARD</div>
                        <div>
                          {toLocaleStringOption(
                            subAmount(
                              `${item.total_reward}`,
                              `${item.withdrawed_reward}`
                            )
                          )}
                        </div>
                        <div>MMT</div>
                      </div>
                      <div
                        className={`${styles.staking} ${
                          subAmount(
                            `${item.unlocked_gmmt}`,
                            `${item.withdrawed_gmmt}`
                          ) != 0
                            ? styles.withDraw
                            : ""
                        }`}
                        // onClick={() => {
                        //   if (withDrawStatus(item.no) == true) {
                        //     onClickMYCOIN(true);
                        //     setWithdrawType("withdraw");
                        //     setModalItem(item);
                        //     setModalType("withdrawGMMT");
                        //   }
                        //   // setModalType("comfirm");
                        //   // setModalType("complete");
                        // }}
                      >
                        <div>LOCK</div>
                        {/* <div>0</div> */}
                        <div>
                          {toLocaleStringOption(
                            // subAmount(
                            //   `${item.locked_gmmt}`,
                            //   `${item.unlocked_gmmt}`
                            // )
                              `${item.locked_gmmt}`,

                          )}
                        </div>
                        <div>GMMT</div>
                      </div>
                      {/* ${styles.withDraw} */}
                      <div
                        className={`${styles.myCoin} ${
                          subAmount(
                            `${item.unlocked_gmmt}`,
                            `${item.withdrawed_gmmt}`
                          ) != 0
                            ? styles.withDraw
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            (subAmount(
                              `${item.unlocked_gmmt}`,
                              `${item.withdrawed_gmmt}`
                            ) !=
                              0) ==
                            true
                          ) {
                            onClickMYCOIN(true);
                            setWithdrawType("withdrawGMMT");
                            setModalItem(item);
                            setModalType("withdrawGMMT");
                          }
                          // setModalType("comfirm");
                          // setModalType("complete");
                        }}
                      >
                        <div>
                          {/* {withDrawStatus(item.no) ? "WITHDRAW" : "MY COIN"} */}
                          UNLOCK
                        </div>
                        <div>
                          {toLocaleStringOption(
                            subAmount(
                              `${item.unlocked_gmmt}`,
                              `${item.withdrawed_gmmt}`
                            )
                          )}
                        </div>
                        <div>GMMT</div>
                      </div>
                    </div>
                    <div
                      className={`${styles.transfer}`}
                      onClick={() => {
                        onClickTransferModal(item);
                      }}
                    >
                      Transfer
                    </div>
                    {/*<span className={styles.unLockText}>TGE UNLOCK : 6</span>*/}
                    {/*<div className={styles.refText}>※ TGE는 Token Generation Event의 약자입니다.</div>*/}
                  </div>
                </div>
              </div>
            ))}
            {/* 여기부터 전산 */}
          </Slider>
        )}
      </div>
      <div className={styles.news}>
        {/* <div className={styles.title}>MMT NEWS</div>
                <div className={styles.newsItem}>
                    <div className={styles.itemTitle}>-</div>
                    <div className={styles.itemDate}>-</div>
                </div>
                <div className={styles.newsItem}>
                    <div className={styles.itemTitle}>-</div>
                    <div className={styles.itemDate}>-</div>
                </div>
                <div className={styles.newsItem}>
                     <div className={styles.itemTitle}>-</div>
                    <div className={styles.itemDate}>-</div>
                </div> */}
      </div>
      {/* className={} */}
      {modalStatus == false ? (
        ""
      ) : (
        // MMT
        <div className={styles.modalWrap}>
          <div
            className={`${styles.modalDim} ${
              modalType === "notice" ? styles.heightAuto : ""
            }`}
            // onClick={() => {
            //   setModalStatus(false);
            // }}
          >
            <div className={`${styles.modalContainer} `}>
              {modalType === "withdraw" ? (
                <div className={styles.withdraw}>
                  <div className={styles.title}>Withdraw</div>
                  <hr />
                  <div className={styles.message}>
                    Would you like to withdraw?
                  </div>
                  <div className={styles.inputBox}>
                    {withdrawType == "withdraw" ? (
                      <div className={styles.inputBoxComponent}>
                        <div>
                          <span className={styles.inputBoxComponentText}>
                            UNLOCK COIN
                          </span>
                          <span className={styles.inputBoxComponentMax}>
                            (MAX{" "}
                            {toLocaleStringOption(
                              subAmount(
                                modalItem.unlocked_mmt,
                                modalItem.withdrawed_mmt
                              )
                            )}
                            )
                          </span>
                        </div>
                        <div className={styles.inputTag}>
                          <input
                            placeholder="0"
                            type={"text"}
                            value={unlockInputAmount}
                            style={{
                              border: `solid 1.5px ${
                                unlockInputStatus ? "#aaa" : "#ff0000"
                              }`,
                            }}
                            onChange={(e) => {
                              onChangeInputValue("unlock", e.target.value);
                            }}
                          />
                          <button
                            className={styles.maxButton}
                            onClick={() => {
                              onChangeInputValue(
                                "unlock",
                                subAmount(
                                  modalItem.unlocked_mmt,
                                  modalItem.withdrawed_mmt
                                )
                              );
                            }}
                          >
                            MAX
                          </button>
                        </div>
                      </div>
                    ) : (
                      // <div className={`${styles.inputBoxComponent} ${styles.child}`}>
                      <div className={`${styles.inputBoxComponent}`}>
                        <div>
                          <span className={styles.inputBoxComponentText}>
                            STAKING
                          </span>
                          <span className={styles.inputBoxComponentMax}>
                            (MAX{" "}
                            {toLocaleStringOption(
                              subAmount(
                                modalItem.total_reward,
                                modalItem.withdrawed_reward
                              )
                            )}
                            )
                          </span>
                        </div>
                        <div className={styles.inputTag}>
                          <input
                            placeholder="0"
                            type={"text"}
                            value={stakingInputAmount}
                            style={{
                              border: `solid 1.5px ${
                                stakingInputStatus ? "#aaa" : "#ff0000"
                              }`,
                            }}
                            onChange={(e) => {
                              onChangeInputValue("staking", e.target.value);
                            }}
                          />
                          <button
                            className={styles.maxButton}
                            onClick={() => {
                              onChangeInputValue(
                                "staking",
                                subAmount(
                                  modalItem.total_reward,
                                  modalItem.withdrawed_reward
                                )
                              );
                            }}
                          >
                            MAX
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.result}>
                    <span className={styles.text}>Withdraw :</span>
                    <span className={styles.amount}>
                      <span>{toLocaleStringOption(withdrawAmount)}</span>
                      <span className={styles.symbol}>MMT</span>
                    </span>
                  </div>
                  <div className={styles.buttons}>
                    <button
                      className={styles.cancelButton}
                      onClick={() => {
                        onClickMYCOIN(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={approveStatus ? "" : styles.disabled}
                      onClick={() => {
                        // onClickApprove()
                        if (approveStatus == true) {
                          setModalType("confirm");
                        }
                      }}
                    >
                      Approve
                    </button>
                  </div>
                </div>
              ) : modalType === "confirm" ? (
                <div className={styles.comfirm}>
                  <div className={styles.title}>Confirm</div>
                  <hr />
                  <div className={styles.message}>
                    Confirm this withdraw in your wallet
                  </div>
                  <div className={styles.comfirmAmount}>
                    <span>{withdrawAmount}</span>
                    <span className={styles.symbol}>MMT</span>
                  </div>
                  <div
                    className={styles.buttons}
                    style={{ marginTop: "16rem" }}
                  >
                    <button
                      className={styles.cancelButton}
                      onClick={() => {
                        onClickMYCOIN(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        onClickConfirm();
                        // setModalType("complete");
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              ) : //
              modalType === "withdrawGMMT" ? (
                <div className={styles.withdraw}>
                  <div className={styles.title}>Withdraw</div>
                  <hr />
                  <div className={styles.message}>
                    Would you like to withdraw?
                  </div>
                  <div className={styles.inputBox}>
                    {withdrawType == "withdrawGMMT" ? (
                      <div className={styles.inputBoxComponent}>
                        <div>
                          <span className={styles.inputBoxComponentText}>
                            UNLOCK COIN
                          </span>
                          <span className={styles.inputBoxComponentMax}>
                            (MAX{" "}
                            {toLocaleStringOption(
                              subAmount(
                                modalItem.unlocked_gmmt,
                                modalItem.withdrawed_gmmt
                              )
                            )}
                            )
                          </span>
                        </div>
                        <div className={styles.inputTag}>
                          <input
                            placeholder="0"
                            type={"text"}
                            value={unlockInputAmount}
                            style={{
                              border: `solid 1.5px ${
                                unlockInputStatus ? "#aaa" : "#ff0000"
                              }`,
                            }}
                            onChange={(e) => {
                              onChangeInputValue("unlockGMMT", e.target.value);
                            }}
                          />
                          <button
                            className={`${styles.maxButton} ${styles.gmmtMaxBtn}`}
                            onClick={() => {
                              onChangeInputValue(
                                "unlockGMMT",
                                subAmount(
                                  modalItem.unlocked_gmmt,
                                  modalItem.withdrawed_gmmt
                                )
                              );
                            }}
                          >
                            MAX
                          </button>
                        </div>
                      </div>
                    ) : (
                      // <div className={`${styles.inputBoxComponent} ${styles.child}`}>
                      <div className={`${styles.inputBoxComponent}`}>
                        <div>
                          <span className={styles.inputBoxComponentText}>
                            STAKING
                          </span>
                          <span className={styles.inputBoxComponentMax}>
                            (MAX{" "}
                            {toLocaleStringOption(
                              subAmount(
                                modalItem.total_reward,
                                modalItem.withdrawed_reward
                              )
                            )}
                            )
                          </span>
                        </div>
                        <div className={styles.inputTag}>
                          <input
                            placeholder="0"
                            type={"text"}
                            value={stakingInputAmount}
                            style={{
                              border: `solid 1.5px ${
                                stakingInputStatus ? "#aaa" : "#ff0000"
                              }`,
                            }}
                            onChange={(e) => {
                              onChangeInputValue("staking", e.target.value);
                            }}
                          />
                          <button
                            className={`${styles.maxButton} ${styles.gmmtMaxBtn}`}
                            onClick={() => {
                              onChangeInputValue(
                                "staking",
                                subAmount(
                                  modalItem.total_reward,
                                  modalItem.withdrawed_reward
                                )
                              );
                            }}
                          >
                            MAX
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.result}>
                    <span className={styles.text}>Withdraw :</span>
                    <span className={styles.amount}>
                      <span>{toLocaleStringOption(withdrawAmount)}</span>
                      <span className={styles.symbol}>GMMT</span>
                    </span>
                  </div>
                  <div className={styles.buttons}>
                    <button
                      className={styles.cancelButton}
                      onClick={() => {
                        onClickMYCOIN(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={`${styles.gmmtColor} ${
                        approveStatus ? "" : styles.disabled
                      }`}
                      onClick={() => {
                        // onClickApprove()
                        if (approveStatus == true) {
                          setModalType("confirmGMMT");
                        }
                      }}
                    >
                      Approve
                    </button>
                  </div>
                </div>
              ) : modalType === "confirmGMMT" ? (
                <div className={styles.comfirm}>
                  <div className={styles.title}>Confirm</div>
                  <hr />
                  <div className={styles.message}>
                    Confirm this withdraw in your wallet
                  </div>
                  <div className={styles.comfirmAmount}>
                    <span>{toLocaleStringOption(withdrawAmount)}</span>
                    <span className={styles.symbol}>GMMT</span>
                  </div>
                  <div
                    className={styles.buttons}
                    style={{ marginTop: "16rem" }}
                  >
                    <button
                      className={styles.cancelButton}
                      onClick={() => {
                        onClickMYCOIN(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={`${styles.gmmtColor}`}
                      onClick={() => {
                        onClickConfirmGMMT();
                        // setModalType("complete");
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              ) : //
              modalType === "complete" ? (
                <div className={styles.complete}>
                  <div className={styles.title}>Complete</div>
                  <hr />
                  <div
                    className={styles.message}
                    style={{ padding: "10rem 0 10rem 0", fontSize: "6rem" }}
                  >
                    Withdraw Complete
                  </div>
                  <div className={styles.buttons}>
                    <button
                      className={styles.goButton}
                      onClick={() => {
                        setModalStatus(false);
                        dispatch(setStatus(""));
                      }}
                    >
                      GO
                    </button>
                  </div>
                </div>
              ) : modalType === "failed" ? (
                <div className={styles.complete}>
                  <div className={styles.title}>Failed</div>
                  <hr />
                  <div
                    className={styles.message}
                    style={{ padding: "10rem 0 10rem 0", fontSize: "6rem" }}
                  >
                    Transaction Rejected
                  </div>
                  <div className={styles.buttons}>
                    <button
                      className={styles.goButton}
                      onClick={() => {
                        setModalStatus(false);
                        dispatch(setStatus(""));
                      }}
                    >
                      GO
                    </button>
                  </div>
                </div>
              ) : modalType === "transfer" ? (
                <div className={styles.withdraw}>
                  <div className={styles.title}>Transfer</div>
                  <hr />
                  <div className={styles.message}>
                    Enter wallet address to send.
                  </div>
                  <div
                    className={styles.inputBox}
                    style={{ marginTop: "2rem" }}
                  >
                    <div className={`${styles.inputBoxComponent}`}>
                      <div>
                        <span className={styles.inputBoxComponentText}>
                          Wallet Address
                        </span>
                      </div>
                      <div className={styles.inputTag}>
                        <input
                          placeholder="0x..."
                          type={"text"}
                          value={walletAddress}
                          style={{
                            border: `solid 1.5px ${
                              inputAddressStatus ? "#aaa" : "#ff0000"
                            }`,
                            width: "95%",
                          }}
                          onChange={(e) => {
                            onChangeWalletAddress(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttons} style={{ marginTop: "7rem" }}>
                    <button
                      className={styles.cancelButton}
                      onClick={() => {
                        setModalStatus(false);
                        setInputAddressStatus(true);
                        setWalletAddress("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={
                        inputAddressStatus == true && walletAddress != ""
                          ? ""
                          : styles.disabled
                      }
                      onClick={() => {
                        if (inputAddressStatus == true && walletAddress != "") {
                          // onClickTransferNFT(transferItem);
                          onClickTransferConfirm();
                        }
                      }}
                    >
                      Approve
                    </button>
                  </div>
                </div>
              ) : modalType === "transferConfirm" ? (
                <div className={styles.comfirm}>
                  <div className={styles.title}>Confirm</div>
                  <hr />
                  <div className={styles.transferMessage}>
                    Would you like to send number{" "}
                    <span style={{ fontWeight: "600" }}>{transferItem.no}</span>{" "}
                    to this address?
                  </div>
                  <div className={styles.transferConfirm}>
                    <span className={styles.address}>{walletAddress}</span>
                    {/* <div className={styles.nftNumber}> number {transferItem.no}</div> */}
                  </div>
                  {transferStatus ? (
                    <div
                      className={styles.buttons}
                      style={{ marginTop: "7rem" }}
                    >
                      <button className={styles.cancelButton}>
                        Approving...
                      </button>
                    </div>
                  ) : (
                    <div
                      className={styles.buttons}
                      style={{ marginTop: "7rem" }}
                    >
                      <button
                        className={styles.cancelButton}
                        onClick={() => {
                          setModalStatus(false);
                          setInputAddressStatus(true);
                          setWalletAddress("");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          onClickTransferNFT(transferItem);
                          // setModalType("complete");
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  )}
                </div>
              ) : modalType === "transferComplete" ? (
                <div className={styles.complete}>
                  <div className={styles.title}>Complete</div>
                  <hr />
                  <div
                    className={styles.message}
                    style={{ padding: "10rem 0 10rem 0", fontSize: "6rem" }}
                  >
                    Transfer Complete
                  </div>
                  <div className={styles.buttons}>
                    <button
                      className={styles.goButton}
                      onClick={() => {
                        setModalStatus(false);
                        setInputAddressStatus(true);
                        setWalletAddress("");
                        dispatch(setStatus(""));
                      }}
                    >
                      GO
                    </button>
                  </div>
                </div>
              ) : modalType === "notice" ? (
                <div
                  className={styles.complete}
                  onClick={() => {
                    setModalStatus(false);
                  }}
                >
                  <div className={styles.title}>Notice</div>
                  <hr />
                  <div
                    className={styles.message}
                    style={{ padding: "4rem", textAlign: "left" }}
                  >
                    <div>
                      On January 3, 2023, Giant Mammoth Chain was successfully
                      launched. The membership cube holder is replaced. We are
                      currently unlocking, 50% of the total value will be
                      released first with MMT, and the remaining 50% will be
                      released with GMMT.
                    </div>
                    <br />
                    <div>
                      0~999 : Total 60,000 <br /> MMT 30,000 / GMMT 30,000
                    </div>
                    <br />
                    <div>
                      1,000~2,499 : Total 70,000 <br /> MMT 35,000 / GMMT 35,000
                    </div>
                    <br />
                    <div>
                      2,500~4,999 : Total 8,0000 <br /> MMT 40,000 / GMMT 40,000
                    </div>
                    <br />
                    <div>* Rewards are paid only in MMT.</div>
                  </div>
                  <div className={styles.buttons}>
                    <button
                      className={styles.goButton}
                      onClick={() => {
                        setModalStatus(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Membership;
